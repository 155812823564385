
import {defineComponent} from "vue";
import VueSearch from "./search";
import {Customer} from "types/customer";
import {Category} from "types/category";
import {Product} from "types/product";
import {AccessControl} from "access_control";
import {Order} from "models/order";
import {Gift} from "types/gift";
import {mattDestroy, mattInit} from "matt_init";
import VuePreloader from "./preloader";
import VueOrderSummaryTable from "./order_summary_table";
import {OrderItem} from "models/order_item";
import {ProductGift} from "types/product_gift";
import {CustomerDiscount} from "types/customer_discount";
import {formatCurrency} from "utils";
import {OrderOptions} from "types/order_options";

const globalAny: any = global;

interface CategoryProduct {
  category: Category,
  products: Product[]
}

interface OrderSection {
  categoryId: number;
  categoryColor: string;
  categoryName: string;
  sectionRows: SectionRow[];
}

interface SectionRow {
  id: number;
  code: string;
  ean: string;
  name: string;
  variant: string;
  gifts: { color: string, description: string }[];
  productGifts: { color: string, description: string }[];
  quantityRange: string;
  customerPrice: string;
  explainDiscountUrl: string;
  detailUrl: string;
  discounts: { colorClass: string; discount: string; tooltip: string; }[]
  inStock: boolean;
  stock: number;
}

export default defineComponent({
  components: {VueOrderSummaryTable, VuePreloader, VueSearch},
  props: ['categoriesUrl', 'productsUrl', 'orderUrl', 'customerUrl', 'customers'],
  name: "order_form",
  methods: {
    t(arg: string, opts?: any) {
      return globalAny.I18n.t(arg, opts)
    },
    loadOrderData() {
      fetch(this.categoriesUrl, {
        headers: {'Accept': 'application/json'},
        cache: 'no-cache'
      }).then(response => response.json()).then((categories: Category[]) => {
        this.categories = categories.filter(category => category.display);
        fetch(this.productsUrl, {
          headers: {'Accept': 'application/json'}
        }).then(response => response.json()).then((products: Product[]) => {
          this.originalProducts = products;
          this.filteredProducts = this.products;
          this.buildCategoryProducts();
          this.order.loadOrderItems(this.products, this.customer);
        }).catch(error => {
          console.error(error);
        });
      }).catch(error => {
        console.error(error);
      })
      fetch(this.orderUrl, {
        headers: {'Accept': 'application/json'},
        cache: 'no-cache'
      }).then(response => response.json()).then((orderOptions: OrderOptions) => {
        this.order = new Order(orderOptions);
      });
    },
    loadCustomer(customerUrl: string) {
      fetch(customerUrl, {
        headers: {'Accept': 'application/json'},
        cache: 'no-cache'
      }).then(response => response.json()).then((customer: Customer) => {
        fetch(customer.products_url, {
          headers: {'Accept': 'application/json'}
        }).then(response => response.json()).then((products: Product[]) => {
          this.customer = customer;
          this.originalProducts = products;
          this.filteredProducts = this.products;
          this.buildCategoryProducts();
          this.order.loadOrderItems(this.products, this.customer);
        })
      })
    },
    buildCategoryProducts() {
      const categoryProducts: CategoryProduct[] = [];
      this.categories.forEach((category: Category) => {
        categoryProducts.push({
          category: category,
          products: []
        } as CategoryProduct)
      });
      this.filteredProducts.forEach((product: Product) => {
        if (!product.category_id) return;

        const categoryId = product.category_id;
        let assigned = false;

        categoryProducts.forEach((categoryProduct: CategoryProduct) => {
          if (assigned) return;

          if (categoryProduct.category.id === categoryId) {
            categoryProduct.products.push(product)
            assigned = true;
          }
        })
      });
      this.categoryProducts = categoryProducts
          .filter(categoryProduct => categoryProduct.products.length > 0);
    },
    giftsForProduct(product: Product): Gift[] {
      if (this.customer.free_gifts == null) return [];

      return this.customer.free_gifts.filter((freeGift: Gift) => {
        const commonTags = freeGift.products.map(tag => tag.name).filter(value => product.tags.map(tag => tag.name).includes(value));
        return commonTags.length > 0;
      })
    },
    productGiftsForProduct(product: Product): ProductGift[] {
      if (this.customer.free_product_gifts == null) return [];

      return this.customer.free_product_gifts.filter((freeGift: Gift) => {
        const commonTags = freeGift.products.map(tag => tag.name).filter(value => product.tags.map(tag => tag.name).includes(value));
        return commonTags.length > 0;
      })
    },
    itemForProduct(product: Product): OrderItem | null {
      const productItem = this.order.order_items.filter(item => {
        return item.product.id === product.id
      })[0];
      if (productItem != null)
        return productItem;
      return null;
    },
    updateFilteredProducts(event: any) {
      this.filteredProducts = event.searchResults;
      this.buildCategoryProducts();
    },
    showAllProducts() {
      this.filteredProducts = this.products;
      this.buildCategoryProducts();
    },
    freeGiftColor(gift: Gift, product: Product): string {
      const grey = '#bdbdbd';

      if (this.order.quantities[product.id] == null || this.order.quantities[product.id] <= 0) {
        return grey;
      }
      const orderItem = this.order.order_items.filter(item => item.product.id === product.id)[0];
      if (orderItem == null || orderItem.appliedGift() !== gift) return grey;

      return gift.badge_color;
    },
    productGiftColor(gift: ProductGift, product: Product): string {
      const grey = '#bdbdbd';

      if (this.order.quantities[product.id] == null || this.order.quantities[product.id] <= 0) {
        return grey;
      }
      const orderItem = this.order.order_items.filter(item => item.product.id === product.id)[0];
      if (orderItem != null && this.order.productGiftAppliesToOrder(gift) && orderItem.productGiftAppliesToItem(gift)) return gift.badge_color;

      return grey;
    },
    subtractQuantity(productId: number) {
      if (this.order.quantities[productId] != null && this.order.quantities[productId] > 0)
        this.order.quantities[productId] -= 1;

      this.updateProductQuantity(productId);
    },
    addQuantity(productId: number, max: number) {
      if (this.order.quantities[productId] == null) {
        this.order.quantities[productId] = 0;
      }
      if (this.order.quantities[productId] < max)
        this.order.quantities[productId] += 1;

      this.updateProductQuantity(productId);
    },
    updateProductQuantity(productId: number) {
      fetch(`${this.orderUrl}/update_product_quantity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': (document.querySelector("[name='csrf-token']") as HTMLMetaElement).content
        },
        body: JSON.stringify({
          product_id: productId,
          quantity: this.order.quantities[productId]
        })
      });
    },
    discountColor(discount: CustomerDiscount): string {
      const discountItems = this.order.itemsForDiscount(discount);

      let combinedQuantity = 0;
      discountItems.forEach(discountItem => {
        combinedQuantity += discountItem.quantity - discountItem.gifted_quantity
      });

      if (combinedQuantity >= discount.min_num_products) {
        return 'light-green lighten-2';
      } else {
        return 'grey lighten-1'
      }
    },
    discountTooltip(discount: CustomerDiscount, productId: number) {
      if (this.order.quantities == null) return '';

      const discountItems = this.order.itemsForDiscount(discount);

      let combinedQuantity = 0;
      discountItems.forEach(discountItem => {
        combinedQuantity += discountItem.quantity - discountItem.gifted_quantity
      });

      if (combinedQuantity >= discount.min_num_products) return this.t('discount_active');
      const missingQuantity = discount.min_num_products - combinedQuantity;
      return `${this.t('buy_at_least')} ${missingQuantity} ${this.t('pluralized_products', {count: missingQuantity})} ${this.t('and_get_discount')}`
    },
    switchOrderCustomer(event: any) {
      const select: HTMLSelectElement = event.currentTarget
      const option: HTMLOptionElement = select.selectedOptions[0];
      if (option.dataset.url != null) this.loadCustomer(option.dataset.url);
    }
  },
  computed: {
    formSections(): OrderSection[] {
      return this.categoryProducts.map(categoryProduct => {
        return {
          categoryId: categoryProduct.category.id,
          categoryColor: categoryProduct.category.color_hex ? categoryProduct.category.color_hex : '#000000',
          categoryName: categoryProduct.category.name,
          sectionRows: categoryProduct.products.map(product => {
            const orderItem = this.itemForProduct(product);
            let customerPrice: number;
            if (orderItem) {
              customerPrice = orderItem.discountedUnitprice();
            } else {
              const fakeItem = new OrderItem(product, 0, this.customer, this.order)
              customerPrice = fakeItem.discountedUnitprice()
            }

            return {
              id: product.id,
              code: product.code,
              ean: product.ean,
              name: product.name,
              new_product: product.new_product,
              variant: product.variant,
              gifts: this.giftsForProduct(product).map(gift => {
                return {
                  color: this.freeGiftColor(gift, product),
                  description: gift.description_human
                }
              }),
              productGifts: this.productGiftsForProduct(product).map(gift => {
                return {
                  color: this.productGiftColor(gift, product),
                  description: gift.description_human
                }
              }),
              quantityRange: product.quantity_range,
              customerPrice: formatCurrency(customerPrice),
              explainDiscountUrl: product.explain_discount_url,
              detailUrl: product.detail_url,
              discounts: product.customer_discounts.map(discount => {
                return {
                  colorClass: this.discountColor(discount),
                  discount: discount.discount,
                  tooltip: this.discountTooltip(discount, product.id)
                }
              }),
              inStock: product.in_stock,
              stock: product.stock
            } as SectionRow
          })
        } as OrderSection
      })
    },
    totalQuantity() {
      let quantitySum = 0;

      Object.keys(this.order.quantities).forEach(productId => {
        quantitySum += this.order.quantities[productId];
      });

      return quantitySum;
    },
    products(): Product[] {
      if (this.onlyNewProducts)
        return this.originalProducts.filter(product => product.new_product);
      else
        return this.originalProducts;
    }
  },
  setup() {
    const accessControl = new AccessControl();
    return {
      ...accessControl.componentSetup()
    };
  },
  data() {
    return {
      categoryProducts: [] as CategoryProduct[],
      categories: [] as Category[],
      originalProducts: [] as Product[],
      filteredProducts: [] as Product[],
      onlyNewProducts: false,
      order: new Order(null),
      customer: {} as Customer,
      timer: null as ReturnType<typeof setTimeout> | null
    }
  },
  mounted() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;
    el.closest('form').addEventListener('submit', this.showAllProducts);

    this.loadOrderData();
    this.order.loadOrderItems(this.products, this.customer);
    this.loadCustomer(this.customerUrl);
  },
  beforeUpdate() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;

    mattDestroy(el);
  },
  updated() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;

    mattInit(el);
  },
  created() {
    this.$watch(
        () => this.order.quantities,
        () => {
          if (this.timer) {
            clearTimeout(this.timer);
          }
          this.timer = setTimeout(() => {
            this.order.loadOrderItems(this.products, this.customer);
          }, 200)
        },
        {deep: true}
    )
  }
})
